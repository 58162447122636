export default [
  "HTML 5",
  "CSS 3",
  "JavaScript ES6",
  "Node.js",
  "Express.js",
  "React",
  "MongoDB",
  "PostgreSQL",
  "SQLite",
  "Firestore",
  "Firebase",
  "Python",
  "Flask",
  "GraphQL"
];

import React, { useState } from "react";

import TechTag from "../../styles/TechTag";
import TechTagContainer from "../../styles/TechTagContainer";

const TechTags = ({ techlist, changeTech }) => {
  const [clickedTags, setClickedTags] = useState([]);
  return (
    <TechTagContainer>
      {techlist.map((technology, i) => (
        <TechTag
          className={clickedTags.includes(i) ? "selected" : null}
          onClick={() =>
            handleClick(technology, i, changeTech, clickedTags, setClickedTags)
          }
          key={i}
        >
          {technology}
        </TechTag>
      ))}
    </TechTagContainer>
  );
};

const handleClick = (
  technology,
  i,
  changeTech,
  clickedTags,
  setClickedTags
) => {
  clickedTags.includes(i)
    ? setClickedTags(clickedTags.filter(tagIndex => tagIndex !== i))
    : setClickedTags([...clickedTags, i]);
  changeTech(technology);
};

export default TechTags;

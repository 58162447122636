import styled from "styled-components";

const LinkList = styled.ul`
  display: flex;
  list-style: none;
  li {
    margin-right: 1em;
    padding-right: 1em;
    border-right: 1px solid black;
  }
  li:last-child {
    border-right: none;
  }
`;

export default LinkList;

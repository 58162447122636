export default [
  {
    title: "LA-HOP Redesign",
    githubLink: "https://github.com/reactionaryzebra/LA-HOP",
    hostedLink: "https://la-hop.firebaseapp.com/",
    tech: [
      "HTML 5",
      "CSS 3",
      "JavaScript ES6",
      "Firestore",
      "Firebase",
      "React"
    ],
    imageSrc: "/images/la-hop_screenshot.png",
    description:
      "Winner of Best in Show, Best in Category, and Fan Favorite at GA Social Impact Hackathon.  This app was built as a mobile-first redesign of the LA Homeless Services Authority's Homeless Outreach Portal tool.  The tool allows social service workers, local business owners, government officials, and concerned citizens to send a request for services on behalf of a person experiencing homelessness.  The app was built over the course of a three day hackathon hosted by GA by three UX/UI designers and two engineers."
  },
  {
    title: "MyGovernment",
    githubLink: "https://github.com/reactionaryzebra/MyGovernment",
    hostedLink: "https://my-government.herokuapp.com/",
    tech: [
      "HTML 5",
      "CSS 3",
      "JavaScript ES6",
      "Node.js",
      "Express.js",
      "React",
      "MongoDB",
      "GraphQL"
    ],
    imageSrc: "/images/mygovernment_screenshot.png",
    description:
      "A tool for citizens to find out more information about the officials who represent them at all levels of government.  Simply by entering your address, you are taken to a portal which will show your representatives at the Federal, State, and Local levels.  Each page will show information about the representative, ways to contact them, and news related to that person.  Built using React hooks and an Express.js server with a GraphQL interface."
  },
  {
    title: "UNO",
    githubLink: "https://github.com/reactionaryzebra/Uno",
    hostedLink: "https://reactionaryzebra.github.io/UNO",
    tech: ["HTML 5", "CSS 3", "JavaScript ES6"],
    imageSrc: "/images/uno_screenshot.png",
    description:
      "My take on the classic card game by Mattel.  This was a sentimental project for me as UNO is a game that I regularly played with the adults with autism I supported at my last job.  Design continually updated in collaboration with a fellow GA graduate from the UX/UI program"
  },
  {
    title: "Savory",
    githubLink: "https://github.com/reactionaryzebra/ProjectRecipe",
    hostedLink: "https://savory-jdj.herokuapp.com/start",
    tech: [
      "HTML 5",
      "CSS 3",
      "JavaScript ES6",
      "Node.js",
      "Express.js",
      "MongoDB"
    ],
    imageSrc: "/images/savory_screenshot.png",
    description:
      "A recipe search app with a social component which allows authenticated users to save recipes they like, see what their friends like, and organize potlucks with those friends to enjoy the dishes they have created.  Built in collaboration with two other GA students in the SEI program."
  },
  {
    title: "No Reservations",
    githubLink: "https://github.com/reactionaryzebra/NoReservations-Flask-API",
    hostedLink: "https://no-reservations-je.herokuapp.com/",
    tech: [
      "HTML 5",
      "CSS 3",
      "JavaScript ES6",
      "Python",
      "Flask",
      "React",
      "PostgreSQL",
      "SQLite"
    ],
    imageSrc: "/images/no-reservations_screenshot.png",
    description:
      "It can be impossible to get reservations at the most popular fine dining locations in Los Angeles.  Frequently reservations are full within the first ten minutes that they become available.  No Reservations is a platform for users to buy and sell reservations to these highly exclusive restaurants.  Built in collaboration with a fellow GA student from the SEI program.  I was responsible for building the back-end."
  },
  {
    title: "Progress Trackr",
    githubLink: "https://github.com/reactionaryzebra/ProgressTrackr",
    hostedLink: "https://task-analysis.firebaseapp.com/",
    tech: [
      "HTML 5",
      "CSS 3",
      "JavaScript ES6",
      "Firestore",
      "Firebase",
      "React"
    ],
    imageSrc: "/images/progress-trackr_screenshot.png",
    description:
      "Task analysis is a commonly used tool for those attempting to teach skills to people with developmental disabilities.  This app allows users to create their own task analyses and keep notes to track the progress of those they are trying to teach.  This allows for better collaboration across educators."
  }
];

import styled from "styled-components";

const TechList = styled.ul`
  display: flex;
  flex-flow: row wrap;
  list-style: none;
  li {
    margin-right: 1em;
    border-right: 1px solid black;
    padding-right: 1em;
  }
  li:last-child {
    border-right: none;
  }
`;

export default TechList;

import styled from "styled-components";

const ProjectsList = styled.ul`
  max-width: 99%;
  max-height: 58%;
  list-style: none;
  overflow-y: auto;
  padding-left: 1em;
  padding-right: 1em;
  > li {
    padding-top: 2em;
    padding-bottom: 2em;
    border-bottom: 1px solid black;
  }
  > li:last-child {
    border-bottom: none;
  }
  @media only screen and (max-width: 1250px) {
    overflow-y: visible;
  }
`;

export default ProjectsList;

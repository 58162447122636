import styled from "styled-components";

const Aside = styled.aside`
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  img {
    width: 80%;
    height: 100%;
    border-radius: 8%;
  }
  h1 {
    font-size: 2em;
  }
  p {
    font-size: 1em;
  }
  a {
    color: white;
  }
  a:not(:last-child) {
    margin-right: 1em;
  }
  a:visited {
    color: white;
    text-decoration: none;
  }
  @media only screen and (max-width: 1250px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 35%;
    & > div:first-child {
      display: flex;
      align-items: center;
    }
    & > div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      div:last-child {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
    img {
      max-height: 8rem;
      max-width: 8rem;
    }
    /* div {
      height: 100%;
    } */
    h1 {
      font-size: 1em;
    }
    p {
      font-size: 0.75em;
    }
  }
`;

export default Aside;

import styled from "styled-components";

const TechTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  height: 3em;
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  margin: 1em;
`;

export default TechTag;

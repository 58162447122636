import React from "react";
import "./App.css";

import Sidebar from "./components/Sidebar";
import ProjectsPage from "./components/ProjectsPage";

function App() {
  return (
    <div className="App">
      <Sidebar />
      <ProjectsPage />
    </div>
  );
}

export default App;

import styled from "styled-components";

const ProjectsSection = styled.main`
  width: 80%;
  height: 100%;
  @media only screen and (max-width: 1250px) {
    width: 100%;
    flex: 1;
    min-height: 0px;
  }
`;

export default ProjectsSection;

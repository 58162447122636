import React from "react";
import TechTags from "../TechTags";
import ProjectContainer from "../../styles/ProjectContainer";
import TechList from "../../styles/TechList";
import LinkList from "../../styles/LinkList";

const Project = ({ project }) => (
  <ProjectContainer>
    <div>
      <img src={project.imageSrc} />
    </div>
    <div>
      <h3>{project.title}</h3>
      <LinkList>
        <li>
          <a target="_blank" href={project.hostedLink}>
            Site
          </a>
        </li>
        <li>
          <a target="_blank" href={project.githubLink}>
            Github
          </a>
        </li>
      </LinkList>
      <p>{project.description}</p>
      <TechList>
        {project.tech.map((technology, i) => (
          <li key={i}>{technology}</li>
        ))}
      </TechList>
    </div>
  </ProjectContainer>
);

export default Project;

import React, { useState, useEffect } from "react";
import TechTags from "../TechTags";
import Project from "../Project";

import ProjectsList from "../../styles/ProjectsList";
import ProjectsSection from "../../styles/ProjectsSection";

import TECHLIST from "../../constants/techlist";
import PROJECTS from "../../constants/projects";

const ProjectsPage = () => {
  const [selectedTech, setSelectedTech] = useState([]);
  const [projectsToDisplay, setProjectsToDisplay] = useState(PROJECTS);

  const changeTech = technologyClicked => {
    selectedTech.includes(technologyClicked)
      ? setSelectedTech(
          selectedTech.filter(technology => technology !== technologyClicked)
        )
      : setSelectedTech([...selectedTech, technologyClicked]);
  };

  const filterProjects = () => {
    selectedTech.length > 0
      ? setProjectsToDisplay(
          PROJECTS.filter(project =>
            selectedTech.every(technology => project.tech.includes(technology))
          )
        )
      : setProjectsToDisplay(PROJECTS);
  };

  useEffect(filterProjects, [selectedTech]);

  return (
    <ProjectsSection>
      <h1>PROJECTS</h1>
      <h2>Technologies Used</h2>
      <TechTags techlist={TECHLIST} changeTech={changeTech} />
      {projectsToDisplay.length > 0 ? (
        <ProjectsList>
          {projectsToDisplay.map((project, i) => (
            <li key={i}>
              <Project project={project} />
            </li>
          ))}
        </ProjectsList>
      ) : (
        <h4>There are no projects that match this search</h4>
      )}
    </ProjectsSection>
  );
};

export default ProjectsPage;

import styled from "styled-components";

const ProjectContainer = styled.article`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  img {
    width: 20vw;
    height: 25vh;
    margin-right: 1em;
  }
  @media only screen and (max-width: 1250px) {
    flex-direction: column;
    img {
      width: 80vw;
      height: 40vh;
    }
  }
`;

export default ProjectContainer;

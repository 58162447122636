import React from "react";
import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa";
import Aside from "../../styles/Aside";

const Sidebar = () => (
  <Aside>
    <div>
      <img src="/images/profilepic.jpg" alt="Justin" />
    </div>
    <div>
      <div>
        <h1>Justin Reznik</h1>
        <p>Justin.Reznik@protonmail.com</p>
        <p>Los Angeles, CA</p>
        <p>(917)565-3222</p>
      </div>
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.github.com/reactionaryzebra"
        >
          <FaGithub size={"2em"} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.github.com/reactionaryzebra"
        >
          <FaLinkedin size={"2em"} />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:justin.reznik@protonmail.com"
        >
          <FaEnvelope size={"2em"} />
        </a>
      </div>
    </div>
  </Aside>
);

export default Sidebar;

import styled from "styled-components";

const TechTagContainer = styled.section`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding-left: 2em;
  padding-right: 2em;
  .selected {
    background-color: black;
    color: white;
  }
`;

export default TechTagContainer;
